import Ampersand from '../Ampersand/Ampersand';
import Corner from '../Corner/Corner';
import { Gota } from '../Gota/Gota';
import lottie from "lottie-web";
import adorno_frase_portada from '../../animations/adorno_frase_portada.json';
import { useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';

function ContainerPortada() {
  const { i18n, t } = useTranslation();
  let animationContainerGota = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainerGota.current,
      renderer: "svg",
      loop: true,
      animationData: adorno_frase_portada
    });
    return () => anim.destroy();
  }, [animationContainerGota]);

  return (
    <div className="portada-container d-flex justify-content-center align-items-center">
          <div className="sombra-vertical sombra-left"></div>
          <Corner class={"injectable adorno-esquina adorno-esquina-0"} />
          <Corner class={"injectable adorno-esquina adorno-esquina-1"} />
          <Corner class={"injectable adorno-esquina adorno-esquina-2"} />
          <Corner class={"injectable adorno-esquina adorno-esquina-3"} />
          <div className="content-portada text-center">
          <div className="language-selector">
              {i18n.language !== 'es' && (<span onClick={() => i18n.changeLanguage('es') } className="fi fi-es"></span>) }
              {i18n.language !== 'it' && (<span onClick={() => i18n.changeLanguage('it') } className="fi fi-it"></span>) }
              {i18n.language !== 'us' && (<span onClick={() => i18n.changeLanguage('us') } className="fi fi-us"></span>) }
            </div>
            <div className="box-nombres-fecha-portada">
              <span className="fecha">{t("date")}</span>

              <div className="iniciales">
                <span>A </span>
                <Ampersand class={"injectable ampersand-portada"}/>
                <span>M </span>
              </div>
              {i18n.language === 'es' && (
                <div>
                  <Button style={{backgroundColor: "#c79747", color:"white", border:"0px", margin:"20px 0 20px 0px"}} target="_blank" href="https://forms.gle/Uft4edmhDAocRFvQ7">Confirmación</Button>
                </div>
              )}

              <div className="nombres">
                <p>Alberto &amp; María</p>
              </div>

            </div>
            <div className="box-frase-portada">
              <Gota/>
              <p>
                {t("frase_bienvenida_1")}    
                <br/>
                {t("frase_bienvenida_2")}   
              </p>
              <div className="anim-frase-portada" ref={animationContainerGota}/>
            </div>
          </div>
    </div>
  );
}

export default ContainerPortada;
